export const environment = {
  name: 'TEST',
  production: true,
  traceSession: true,
  kcRealmUrl: 'https://auth1.wastics.eu/',
  kcRealm: 'Wastics',
  apiUrl: 'https://awk-api.wastics.eu/awk-service/api/v1',
  awkExcelApi: 'https://excel9vb78nuh.wastics.eu/v1/data',
  puppeteerApiUrl: 'https://pdff7qz5ptu.wastics.eu',
  quarkusApiUrl: 'https://dwrm5blokw2y.wastics.eu/v1',
  matchMakingApiUrl: 'https://ms53gktnk.wastics.eu',
  notifications: {
    publicKey:
      'BI_GDu4lN8YzDYikXyYD-nx4rRm6pdZ3MP1jCY4JNBBiZKWMp2Re-uJTqjmrdm2okh_xB0B_uWxUbq_jf1ZDV3A',
  },
  umamiId: '',
};
